:root{
    --Primary: #38D5BA;
    --Secondary: #003061;
    --dark:#141414;
    --navbar:"hidden";
    --green:#80D3B9;
    --azul:#1D3360;
    --gray:#808080;
} 

//Sliders
.owl-item{
    // width: auto!important;
}
.lg-autoplay-button{
    display: none!important;
}
.swal2-styled.swal2-confirm {
    background-color: var(--Secondary) !important;
}
.owl-nav{
    display: none;
    .owl-prev,.owl-next{
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 3vw;
        height: 3vw;
        
        svg{
            height: 100%;
        }
    }
    .owl-prev{left: 5%;cursor: pointer;}
    .owl-next{right: 5%;cursor: pointer;}
}
.owl-stage{
    display: flex;
    flex-direction: row;
    height: 100%;
}
.owl-stage-outer{
    overflow: hidden;
    position: relative;
    height: 100%;
    overflow: hidden;
}
.owl-carousel{
    width: 100%;
    height: 100%;
    position: relative;
    font-family: "aldrich";
}
// end Slider
.Section{
    width: 100%;
    height: var(--height);
    min-height: 700px;
    position: relative;
}
.flex-row{
    display: flex;
    flex-direction: row;
}
.flex-column{
    display: flex;
    flex-direction: column;
}
.text-center{
    text-align: center;
}
.p-absolute{
    position: absolute;
}
.mb-1{
    margin-bottom: 1vw;
}
.mr-1{
    margin-right: .5vw;
}
.img-expand{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.desktop{
    display: none!important;
}
.movil{
    display: block!important;
}
@media(min-width:700px){
    .desktop{
        display: flex!important;
    }
    .movil{
        display: none!important;
    }
}
.lg-thumb-item.active{
    border-color: var(--Primary)!important;
}
//Colores
.Primary{
    color: var(--Primary);
}
.Secondary{
    color: var(--Secondary);
}
.white{
    color: white;
}

//
.pointer{
    cursor: pointer;
}
.img-fluid{
    width: 100%;
}

.d-block{
    display: block;
}
.mx-auto{
    margin: 0 auto 0 auto;
}
// style size dimentions
.container{
    padding: 0 1rem;
    @media(min-width:720px){
        max-width: 720px;
    }
    @media(min-width:960px){
        max-width: 960px;
    }
    @media(min-width:1200px){
        max-width: 1200px;
    }
}
// iconos
.left{
    mask: url("../../../Assets/images/iconos/signalLeft.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
}
.right{
    mask: url("../../../Assets/images/iconos/signalRight.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
}