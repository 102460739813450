
button,.change{
    cursor: pointer;
}
.term-and-condition{
    text-decoration: underline;
}
.formulario-model{
    .content-select{
        width: 100%;
        display: flex;
        .button-select{
            width: 10vw;
            height: 11vw;
            border-radius: 2vw;
            border: 1px solid #00000038;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 0.5vw;
            margin: 4vw 3vw 5vw 0;
            transition: .3s;
        }
        .button-select:hover{
            box-shadow: 0px 4px 12px -5px rgba(0, 0, 0, 0.541);
            background: var(--text);
            color: white;
        }
        .option-dorm{
            position: absolute;
            visibility: hidden;
            pointer-events: none;
        }
        #option-1:checked ~ [for="option-1"],#option-2:checked ~ [for="option-2"],#option-3:checked ~ [for="option-3"]{
            border: 0;
            background: white;
            color: var(--text);
            transition: .5s;
        }
        .option-dorm.invalid ~ .button-select{
            border:2px solid red;
        }
    }
    .content-inputs{
        .input{
            width: 100%;
            border: 0;
            border-radius: 2vw;
            font-size: 4vw;
            padding: 3.5vw;
            margin-bottom: 3vw;
            border: 2px solid transparent;
        }
        .input.invalid{
            border: 2px solid red;
        }
    }
    .terminos{
        display: flex;
        justify-content: center;
        margin-top: 4vw;
        span{
            font-size: 3.5vw;
        }
       
        #term{
            position: absolute;
            visibility: hidden;
            pointer-events: none;
        }
        #term:checked ~ .change{
            background: var(--text);
        }
        input[type=checkbox].invalid ~ .change{
            border-color: red;
        }
        .change{
            height: 4vw;
            width: 4vw;
            border: 1px solid;
            display: block;
            margin-right: 2vw;
        }
    }
    button[type=submit]{
        width: 36vw;
        height: 11vw;
        border-radius: 50rem;
        border: 0;
        background: var(--green);
        font-size: 5vw;
        font-weight: bold;
        display: block;
        margin: auto;
        margin-top: 5vw;
        margin-bottom: 4vw;
    }
}

@media(min-width:700px){
.Page-model{
    .contactanosModel{
        margin: 0;
        padding: 0!important;
        width: 100%;
        .title-contactModel{
            font-size: 4rem;
            width: 100%;
        }
    }
}
.formulario-model{
    font-size: 1.2rem;
    width: auto;
    height: auto;
    padding: 5rem 1rem 10rem 1rem;
    border-radius: .5rem;
    display: flex;
    flex-wrap: wrap;
    --paragraph: 1rem;
    .content-title{
        display: flex;
        align-items: center;
        margin-bottom: 1.8rem;
        .title{
            font-size: var(--paragraph);
            margin-right: 1rem;
        }
    }
    .content-select{
        width: auto;
        .button-select{
            font-size: 1.2rem;
            width: 3vw;
            height: 3.5vw;
            padding: .6rem;
            border-radius: .5rem;
            margin: 0;
            margin-right: 1rem;
            cursor: pointer;
        }
    }
    .content-inputs{
        display: flex;
        width: 70%;
        margin-right: 17%;
        .input{
            font-size: var(--paragraph);
            padding: .7rem 1rem;
            border-radius: .5rem;
            margin-right: 1rem;
        }
    }
    .terminos{
        align-items: center;
        justify-content: flex-start;
        margin-top: 0;
        order: 2;
        width: auto;
        .change{
            width: var(--paragraph);
            height: var(--paragraph);
            margin-right: .5rem;
        }
        span{
            font-size: var(--paragraph);
        }
    }
    button[type=submit]{
        margin: 0;
        width: 10rem;
        height: 2.5rem;
        font-size: var(--paragraph);
        order: 1;
        margin-right: 2rem;
        transition: .3s;
    }
    button[type=submit]:hover{
        transform: scale(1.02);
        box-shadow: 0 6px 10px -4px #00000063;
    }
}
}
