
:root{
    --barra:2rem;
    --height:0.16rem;
    --barra3:1.2rem;
    --nmargin:0.14rem;
    --margin:0.14rem 0;
    --total: calc(var(--nmargin) * 2 + var(--height));
    
    @media(orientation:portrait),(max-width:700px){
        --barra:2.4rem;
        --barra3:1.8rem;
        --margin:0.2rem 0;
        --height:0.2rem;
        --nmargin: 0.2rem;
        --total: calc(var(--nmargin) * 2 + var(--height));
    }
}
@mixin  flex-center(){
    display: flex;
    align-items: center;
    justify-content: center;
}
.icon-facebook{
    mask-image: url("../../Assets/images/iconos/facebook-menu.svg");
    mask-size: contain;
    mask-position: center;
    mask-repeat: no-repeat;
    background: white;
}
#nav-transparent{
    background: white;
    box-shadow: 0px 0px 20px -2px rgba(0, 0, 0, 0.15);
    .proyecto{
        color: var(--Secondary);
    }
    .icono{
        .cls-1{
            fill:var(--Secondary);
        }
    }
    .brand{
        .brand-biabo{
            svg{
                .biabo-1{
                    fill: var(--Secondary);
                }
            }
        }
    }
}
.Navbar.inactive{
    @media(max-width:700px){
        background: white;
        box-shadow: 0px 0px 20px -2px rgba(0, 0, 0, 0.15);
        .biabo-1{
            fill: var(--Secondary)!important;
        }
    }
}
.Navbar.inactiveDesktop{
    background: white;
    box-shadow: 0px 0px 20px -2px rgba(0, 0, 0, 0.15);
    .biabo-1{
        fill: var(--Secondary)!important;
    }
    .content-links{
        .proyecto{
            color: var(--Secondary);
        }
    }
    .icono{
        .cls-1{
            fill: var(--Secondary);
        }
    }
}
.Navbar{
    position: fixed;
    top: 0px;
    height: 4.5rem;
    width: 100%;
    z-index: 999;
    padding: 0 3.5vw;
    @media(min-width:1400px){
        height: 5rem;
    }
    .brand{
        float: left;
        height: 100%;
        width: 15%;
        .brand-biabo{
            height: 100%;
            width: 12.7vw;
            display: flex;
            align-items: center;
            svg{
                height: 54%;
                .biabo-1{
                    fill: white;
                }
            }
            img{
                height: 70%;
                width: auto;
            }
        }
    }
    .content-links{
        height: 100%;
        .link{
            margin: 0 .25vw;
            height: 100%;
            display: flex;
            float: right;
            color: white;
            @include flex-center();
            cursor: pointer;
            .icono{
                height: 1rem;
                width:  1rem;
                display: flex;
                cursor: pointer;
                svg{
                    height: 100%;
                }
            }
        }
        .link-toggle{
            margin-left: 1.5vw;
            @media(max-width:740px){
                padding-bottom: 0.2rem;
            }
        }
        .toggle{
            height: 2vw;
            width: auto;
            @include flex-center();
            flex-direction: column;
            left: .5vw;
            // position: relative;
            z-index: 1;
            cursor: pointer;
            .barra{
                height: var(--height);
                min-height: var(--height);
                width: var(--barra);
                background:var(--Primary);
                align-self: flex-start;
                margin:var(--margin);
                position: relative;
                top: 0;
            }
            .barra-3{
                width: var(--barra3);
            }
        }
        .toggle:hover{
            .barra{
                // box-shadow: 0px 0px 3px 0px #38D5BA;
            }
        }
        .proyecto{
            margin-right: 1.5vw;
            font-size: 1rem;
            letter-spacing: .05vw;
            font-family: "GtWalsheimProRegular";
        }
    }
    .Menu{
        position: absolute;
        height: 100vh;
        width: 100%;
        left: 0;
        top: 0;
        background: var(--Secondary);
        left: 100%;
        height: auto;
        min-height: 100vh;
        .biabo-menu-img{
            position: absolute;
            height: 4vw;
            top: 1vw;
            left: 2vw;
        }
        .content-link-menu{
            display: flex;
            align-items: center;
            .proyectos{
                display: flex;
                flex-direction: column;
                width:43vw; 
                height:85vh;
                color: white;
                font-size: 3vw;
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                padding-left: 14vw;
                padding-top: 6.7vw;
                
                
                .link-proyectos{
                    font-family: "MarkProMedium";
                    text-align: left;
                    margin-bottom: 2vw;
                    opacity: 0.9;
                    color: white;
                    font-size: 2rem;
                    .project.active{
                        color:var(--Primary)
                    }
                    .project:hover{
                        color:var(--Primary)
                    }
                    .direccion{
                        color: #78787E;
                        font-size: .9rem;
                        font-family: "MarkPro";
                        position: relative;
                        top: -0.3vw;
                        font-weight: 100;
                    }
                    .show-project{
                        position: relative;
                    }
                    .show-project::after{
                        content: "";
                        position: absolute;
                        top: 1.5vw;
                        left: 12vw;
                        width: 15.5vw;
                        height: .2vw;
                        background: var(--Primary);
                    }
                }
                .title{
                    font-size: 1.3rem;
                    letter-spacing: .07vw;
                    color: #78787E;
                    opacity: 1;
                }
            }
            .menu-links{
                flex-direction: column;
                display: flex;
                justify-content: flex-start;
                height: 85vh;
                padding-top: 11.6vw;
                
                .link{
                    color: #ffffff66;
                    font-size: 1.7rem;
                    margin-bottom: 1vw;
                    cursor: pointer;
                    display: flex;
                    align-items: flex-end;
                    .icon{
                        height: 1.2rem;
                        width: 1.2rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-left: 0.1rem;
                        margin-right: 0.1rem;
                        margin-bottom: .3rem;
                        .icon-facebook{
                            width: 0;
                            height: 0;
                            padding: 2vw;
                            margin-bottom: 1.5vw;
                            background: #ffffff66;
                            @media(min-width:740px){
                                margin-bottom: 0.25rem;
                                padding: .6rem;
                            }
                        }
                    }
                    .tel{
                        font-size: 1.2rem;
                        text-decoration: none;
                        color: #ffffff66;
                        margin-left: .3rem;
                        margin-right: 0.1rem;
                        margin-bottom: .17rem;
                    }
                    .t-project{
                        margin-right: 0.4rem;
                    }
                    .t-project:hover{
                        color: var(--Primary);
                    }
                }
                #menu-inicio{
                    font-size: 2rem;
                }
                .link:hover{
                    .tel,
                    .t-project{
                        color: var(--Primary);
                    }
                    .icon-facebook{
                        background: var(--Primary);
                    }
                }
                .active{
                    color: var(--Primary);
                    .t-project,.tel{
                        color: var(--Primary);
                        @media(max-width:740px){
                            color: var(--Primary);
                        }
                    }
                    .icon-facebook{    
                        background: var(--Primary)!important;
                    }
                }
                
                

            }
            .other-links{
                height: 85vh;
                flex-direction: column;
                display: flex;
                justify-content: center;
                height: 85vh;
                padding-top: 6.7vw;
                margin-left: 14vw;
                .title-other-links{
                    margin-bottom: .7vw;
                    color: white;
                    font-family: "MyriadPro-Regular";
                    font-size: 1.2vw;
                }
                .subtitle{
                    color: #ffffff66;
                    font-family: "MyriadPro-Regular";
                    font-size: 1.2vw;
                }
                .final{
                    margin-bottom: 2.2vw;
                }
            }
        }
        .barra-menu{
            display: flex;
            width:54.24064932480515vw;
            height: 15vh;
            align-items: center;
            justify-content: space-between;
            margin: auto;
            color: #78787E;
            border-top: 1px solid;
            position: relative;
            .barra-link{
                font-family: "MarkPro";
                font-size: 1.3vw;
                color: #78787E;
            }
            .icon{
                display: flex;
                height: 1.4vw;
                width: 1.4vw;
                svg{height: 100%;}
            }
            .facebook{
                position: absolute;
                right: 0vw;
            }
        }
    }
    #facebook,#instagram{
        transition: .6s;
    }
    .instagram{
        visibility: hidden;
    }
    .instagram:hover{
        #instagram{
            fill: var(--Primary)!important;
        }    
    }
    .facebook:hover{
        #facebook{
            fill: var(--Primary)!important;
        }    
    }
    .Menu.ON{
        transition: all 0.3s cubic-bezier(0.94, 0.15, 0.16, 0.84);
        left: 0%;
    }
    .Menu.OFF{
        transition: all 0.3s cubic-bezier(0.94, 0.15, 0.16, 0.84);
        left: 100%;
    }
}


.ON{
    .barra-1{animation: toggle_1 .5s forwards }
    .barra-2{visibility: hidden;transition: .1s;}
    .barra-3{animation: toggle_2 .5s forwards}
}
.OFF{
    animation: 2.1s transposicion forwards;
    .barra-1{animation: toggle_1_hide .3s forwards}
    .barra-2{visibility: visible;transition: .5s;transition-delay: .3s;}
    .barra-3{animation: toggle_2_hide .3s forwards}
}

@keyframes toggle_1_hide{
    0%{
        transform: rotate(47deg);
        top: var(--total);
    }
    100%{
        top: 0;
    }
}
@keyframes toggle_2_hide{
    0%{
        transform: rotate(-47deg);
        top: calc(-1 * var(--total));
        width: var(--barra);
    }
    100%{
        top: 0;
        width: var(--barra3);
    }
}

@keyframes toggle_1{
    0%{top: 0;}
    100%{
        transform: rotate(47deg);
        top: var(--total);
    }
}
@keyframes toggle_2{
    0%{
        top: 0;
    }
    100%{
        transform: rotate(-47deg);
        width: var(--barra);
        top: calc(-1 * var(--total));
    }
}

@media (orientation:portrait),(max-width:700px){
    .Navbar{
        height: 4.5rem;
        padding: 0 4.5vw!important;
        .brand{
            .brand-biabo{
                width: 31vw!important;
            }
        }
        .content-links{
            
            .toggle{
                height: 6vw!important;
                width: auto!important;
            }
            .facebook,.instagram,.proyecto{
                display: none!important;
            }
        }
        .Menu{
            .biabo-menu-img{
                height: 6%;
                top: 4.5vw;
                left: 5vw;
            }
            .content-link-menu{
                margin-bottom: 4vw;
                flex-wrap: wrap;
                .link-proyectos{
                    user-select: none;
                    font-size: 7.2vw!important;;
                    .direccion{
                        font-size: 2.9vw!important;;
                    }
                    .show-project:after{
                        display: none;
                    }
                }
                .proyectos{
                    height: 100vh!important;
                    display: flex;
                    align-items: flex-start;
                    justify-content: center!important;
                    margin-top: 0vw;
                    padding-top: 0;
                }
                .proyectos,.menu-links{
                    height: 40vh;
                    width: 100%;
                }
                .menu-links{
                    padding-left: 0vw;
                    height: auto;
                    justify-content: center;
                    padding-top: 3.5vw;
                    padding-left: 7vw;
                    .link{
                        font-size: 5.5vw;
                        margin-bottom: 2.5vw;
                        .icon{
                            height: 4vw;
                            width: 4vw;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-left: .1vw;
                            margin-bottom: 0;
                        }
                        .tel{
                            font-size: 4.3vw;
                            margin-bottom: 0.3vw;
                        }
                    }
                }
                .other-links{
                    justify-content: flex-start;
                    .title-other-links{
                        font-size: 4vw;
                    }
                    .subtitle{
                        font-size: 3.7vw;
                    }
                }
                .proyectos{
                    justify-content: flex-start;
                    height: auto;
                    .title{
                        font-size: 2.5vh;
                    }
                    a{
                        font-size: 3vh;
                    }
                }
            }
           
            .barra-menu{
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    width: 76vw;
                    .barra-link{
                        font-size: 2vw;
                    }
                    .facebook{
                        position: relative;
                        left: 6vw;
                    }
                    .icon{
                        display: flex;
                        height: 3vw;
                        width: 3vw;
                    }
            }
        }
    }
}