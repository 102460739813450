
.contacto{
    --input:white;
    --title:white;
    padding-top: 7.2vw;
    background: var(--Secondary);
    
    .title{
        // padding: 6.7vw 0 1.5vw 9.8vw;
        font-size: 2vw;
        color:var(--title);
        font-family: "GtWalsheimProLight";
        text-align: center;
        letter-spacing: 0.1vw;
        .bold{
            color:var(--title);
        }
    }
    .paragraph{
        font-size: 1.6vw;
        padding-bottom: 2.6vw;
        color:var(--title);
        text-align: center;
        font-family: "GtWalsheimProLight";
    }
    .formulario{
        display: flex;
        flex-wrap: wrap;
        padding: 0 15vw 0vw 15.7vw;
        justify-content: space-between;
        .input-title{
            color: white;
            font-size: 1vw;
            margin: .5vw 0;
            font-family: "GtWalsheimProLight";
        }
        .input{
            background: var(--input);
            border: 0;
            border-bottom: 1px solid var(--title);
            width: 31.6vw;
            height: 3.5vw;
            margin-bottom: 1.3vw;
            color: var(--Secondary);
            padding: 0vw 1vw;
            font-size: 1.1vw;
            transition: 250ms ease-in-out;
            &.invalid {
                border: 2px solid red;
                border-color: red;
                color: red;
                &::placeholder {
                    color: red;
                }
            }
        }
        .col{
            // margin-right: 4vw;
        }
        .input::placeholder{
            font-size: 1.3vw;
            font-family: "MarkPro";
            color: var(--title);
        }
        .btn{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            .btn-submit{
                width: 7.6vw;
                height: 2.9vw;
                background: var(--Primary);
                color: var(--submit);
                font-size: 1.3vw;
                border: 0em;
                margin-bottom: 8vw;
                margin-right: 0.8vw;
                cursor: pointer;
                transition: .3s;
                font-family: "GtWalsheimProMedium";
            }
            .btn-submit:hover{
                background:var(--input);
                color: black;
            }
        }
    }
    .button-radius{
            width: 92%;
            user-select: none;
            margin: 1vw 0 2.5vw 0vw;
            .radio{
                display: flex;
                align-items: center;
                justify-content: center;
                .boton-radio{
                    display:none;
                    &.invalid + .radius{
                        border-color: red;
                    }
                }
                .radius{
                    height: 1.2rem;
                    width: 1.2rem;
                    background: var(--Yellow);
                    border: 1px solid var(--title);
                    border-radius: 100%;
                    margin-right: 1vw;
                }
                #link-terminos{
                    position: relative;
                    top: -0.1vw;
                    border-radius: 0;                }
                #particular:checked ~ #button-1,#oficina:checked ~ #button-2,#Empresa:checked ~ #button-3{
                    background: white;
                    transition: .1s;
                }
                #terminos:checked~#link-terminos{
                    background: var(--title);
                    transition: .5s;
                }

                .button-title{
                    color: var(--title);
                    font-size: 1.3vw;
                    position: relative;
                    top: 0.1vw;
                    cursor: pointer;
                    align-items: center;
                    justify-content: center;
                    .terminos{
                        color: var(--title);
                        text-decoration: underline;
                    }
                }
            }
        }
        .button-radius{
            width: 92%;
            margin-bottom: 3vw;
            user-select: none;
            .radio{
                display: flex;
                align-items: center;
                justify-content: center;
                .boton-radio{
                    display:none;
                    &.invalid + .radius{
                        border-color: red;
                    }
                }
                .radius{
                    height: 1.2rem;
                    width: 1.2rem;
                    background: var(--Yellow);
                    border: 1px solid var(--title);
                    border-radius: 100%;
                    margin-right: 1vw;
                }
                #link-terminos{
                    position: relative;
                    top: -0.1vw;
                    border-radius: 0;                }
                #particular:checked ~ #button-1,#oficina:checked ~ #button-2,#Empresa:checked ~ #button-3{
                    background: white;
                    transition: .1s;
                }
                #terminos:checked~#link-terminos{
                    background: var(--title);
                    transition: .5s;
                }

                .button-title{
                    color: var(--title);
                    font-size: 1.3vw;
                    position: relative;
                    top: 0.1vw;
                    cursor: pointer;
                    align-items: center;
                    justify-content: center;
                    .terminos{
                        color: var(--title);
                        text-decoration: underline;
                    }
                }
            }
            #terminos{
                position: absolute;
                visibility: hidden;
            }
        }
}
@media (max-width:700px){
    $title:4.5vw;

    .contacto{
        .title{
            text-align: center;
            padding: 5vw 1vw 3vw 1vw;
            font-size: 6vw;
            width: 62vw;
            margin: auto;
        }
        .paragraph{
            font-size: 3.8vw;
            width: 55vw;
            text-align: center;
            padding: 0 0 5vw 0;
            margin: auto;
            color:var(--title);
        }
        .formulario{
            padding: 0 6vw 0vw 6vw;

            .input-title{
                font-size: 3.6vw;
            }
            .flex-column{
                width: 100%;
            }
            .input{
                width: 100%;
                margin: 0;
                height: 11vw;
                font-size: 3.5vw;
                margin-bottom: 3vw;
                margin-top: 1.2vw;
            }
            .input::placeholder{
                font-size: 3.5vw;;
            }
            .radius{
                height: 1rem!important;;
                width: 1rem!important;;
            }
            .button-title{
                min-width: 100vw!important;;
                font-size: 3.4vw!important;
            }
            .button-radius{
                margin: 4vw 0 7.0vw 0vw;
            }
            .btn{
                .btn-submit{
                    width: 38.6vw;
                    height: 14vw;
                    font-size: 5.3vw;
                }
                
            }
        }
    }
}