// in this sheet are the css globals about pages of 'contacto'
.clip{
    mask: url("../../Assets/images/iconos/svg/clip.svg");
    mask-repeat: no-repeat;
    mask-size: contain;
    mask-position: center;
}
.green{
    color: var(--green);
}
.section-header{
    width: 100%;
    height: 111vw;
    padding:0 6vw;
    padding-top: 5rem;
    margin-bottom: 150vw;
    background: url("../../Assets/images/contacto/trade.png");
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    .title-1{
        font-size: 4.5vw;
        letter-spacing: 1vw;
        position: relative;
    }
    .title-2{
        font-size: 8vw;
        margin: 1vw 0;
        margin-bottom: 5vw;
        position: relative;
    }
    .sub-title{
        color: white;
        text-align: center;
        display: block;
        font-size: 4.5vw;
        position: relative;
    }
    .paragraph{
        font-size: 4.3vw;
        position: relative;
    }
}
.section-header::before{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #1d3360de;
    z-index: 0;
}
.Main-contacto.contactanos{
    .section-header{
        padding:0 9vw;
        padding-top: 7.5rem;
        .title{
            margin-bottom: 5vw;
        }
    }
}
.Main-contacto.inversionistas,.Main-contacto.vendetuterreno{
    .section-header{
        height: 131vw;
        padding:0 12vw;
        padding-top: 7.5rem;
    }
    .formulario{
        top: 104vw;
    }
}
.Main-contacto.vendetuterreno{
    .section-header{
        height: 205vw;
    }
    .paragraph{
        margin-bottom: 5vw;
    }
    .formulario{
        top: 148vw;
    }
}
.Main-contacto{
    .formulario{
        width: 100%;
        background: white;
        box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.342);
        margin-bottom: 150vw;
        position: absolute;
        top: 56vw;
        width: 88%;
        display: block;
        left: 0;
        right: 0;
        margin:auto;
        border-radius: 4vw;
        padding: 0 6vw;
        .title-form{
            color: #333333;
            font-size: 8vw;
            display: block;
            text-align: center;
            margin: 5vw 0;
        }
        .input{
            width: 100%;
            height: auto;
            position: relative;
            input,select,textarea{
                width: 100%;
                font-size: 4vw;
                padding: 3vw;
                margin: 1vw 0;
                position: relative;
                z-index: 0;
                border-radius: 3vw;
                border: 2px solid rgba(0, 0, 0, 0.178);
                color: var(--gray);
                &.invald {
                    border-color: red;
                }
            }
            input[type=file].invalid ~ .input-file{
                border: 2px solid red;
            }
            textarea{
                height: 34vw;
            }
            textarea.invalid{
                border: 2px solid red;
            }
            input[type=file]{
                position: absolute;
                visibility: hidden;
            }
            .input-file{
                display: flex;
                border: 1px solid rgba(0, 0, 0, 0.178);
                border-radius: 3vw;
                border-color: #00000038;
                justify-content: space-between;
                padding: 1vw 3vw;
                align-items: center;
            }
            .content-description{
                display: flex;
                flex-direction: column;
                width: 70%;
                .title-description{
                    font-size: 4vw;
                    color: var(--gray);
                }
                .sub-title-description{
                    font-size: 3.2vw;
                    color: var(--gray);
                }
            }
            .clip{
                width: 9vw;
                height: 8vw;
                background: #808080;
            }
        }
        .terminos{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 3vw;
            input[type=checkbox]{
                position: absolute;
                visibility: hidden;
            }
            input[type=checkbox].invalid ~ .change {
                border: 2px solid red;
            }
            .change{
                width: 4vw;
                height: 4vw;
                display: block;
                border: 1px solid;
                margin-right: 2vw;
            }
        }
        .paragraph-term{
            color:var(--gray);
            font-size: 3.6vw;
            a{
                text-decoration: none;
                color: #333333;
            }
        }
        button{
            width: 34vw;
            height: 11vw;
            border-radius: 2vw;
            border: 1px solid var(--green);
            color: var(--green);
            background: white;
            font-size: 6vw;
            display: block;
            margin: 6vw auto 9vw auto;
            cursor: pointer;
        }
       
    }
}

#term:checked ~ .change {
    padding: .6%;
    background: var(--dark);
    .checked{
        mask: url('../../Assets/images/iconos/svg/correct.svg');
        mask-size: contain;
        mask-position: center;
        mask-repeat: no-repeat;
        width: 100%;
        height: 100%;
        background: skyblue;
    }
}
@media(min-width:700px){
.Main-contacto{
    display: flex;
    justify-content: center;
    position: relative;
    padding-top: 10.3rem;
    padding-bottom: 8rem;
    padding-left: 2rem;
    padding-right: 2rem;
    background: url("../../Assets/images/contacto/trade.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    --text:1rem;
    .section-header{
        background: none;
        padding: 0!important;
        width: 340px;
        height: auto!important;
        padding-top: 7.5rem;
        margin-bottom: 0;
        margin-right: 3rem;
        margin-top: 7%;
    }
    .section-header::before{
        display: none;
        background: transparent;
    }
    .title-1{
        font-size: 1.3rem;
        letter-spacing: .5rem;
        text-align: left;
    }
    .title-2{
        font-size: 2.6rem;
        margin: 1rem 0;
        text-align: left;
    }
    .paragraph{
        font-size: var(--text);
        text-align: left;
        margin-bottom: 1rem!important;
    }
    .formulario{
        position: relative!important;
        top: 0!important;
        width: 340px;
        padding: 1rem 1.5rem;
        margin: 0;
        border-radius: 1rem;
        .title-form{
            font-size: 2.3rem;
            margin: 1rem;
        }
        
        .input{
   
            input,select,.input-file{
                width: 100%;
                font-size: var(--text);
                padding: .5rem 1rem;
                margin: .3rem 0;
                position: relative;
                z-index: 0;
                border-radius: .5rem;
                &.invalid{
                    border-color: red;
                }
            }
            textarea{
                font-size: var(--text);
                padding: .5rem 1rem;
                border-radius: .5rem;
                height: 7rem;
                margin: .3rem 0;
            }
            .content-description{
                .title-description{
                    font-size: var(--text);
                }
                .sub-title-description{
                    font-size: .8rem;
                }
            }
            .clip{
                width: 2rem;
                height: 2rem;
            }
        }
        .terminos{
            margin-top: 0;
            .change{
                width: 1rem;
                height: 1rem;
                margin-right: .6rem;
                cursor: pointer;
            }
        }
        .paragraph-term{
            font-size: 1rem;
        }
        button{
            width: 10rem;
            height: auto;
            font-size: 1rem;
            padding: .5rem 1rem;
            border-radius: .5rem;
            margin: 1.2rem auto;
        }
        button:hover{
            box-shadow: inset 0 -3.25em 0 0 var(--hover);
        }
    }
}
.Main-contacto::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #1d3360de;
}
.Main-contacto.inversionistas{
    background: url("../../Assets/images/contacto/investor.png")!important;
    background-repeat: no-repeat;
    background-size: cover;
    .section-header{
        height: auto;
        width: 480px;
    }
    .section-header::before{
        background: transparent;
    }
}
.Main-contacto.vendetuterreno{
    
    .section-header{
        padding: 0;
        width: 400px;
        margin-right: 6rem;
    }
}
.Main-contacto.contactanos{
    background: url("../../Assets/images/contacto/center-call.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    .section-header{
        .title-1{
            font-size: 2rem;
            margin-bottom: 1rem;
        }
        .sub-title{
            font-size: 1.3rem;
            text-align: left;
        }
    }
}
}